var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "a-card",
        {
          attrs: {
            title: _vm.isModeCreate
              ? _vm.$t("common.create-text", {
                  text: _vm.$t("lbl_cash_in_out")
                })
              : _vm.isModeEdit
              ? _vm.$t("common.edit-text", { text: _vm.$t("lbl_cash_in_out") })
              : _vm.$t("lbl_cash_in_out_view")
          }
        },
        [
          _c(
            "a-form-model",
            {
              ref: "CashForm",
              attrs: {
                model: _vm.formModel,
                rules: _vm.formRules,
                "wrapper-col": { span: 14 },
                "label-col": { span: 6 },
                "label-align": "left",
                layout: "horizontal"
              }
            },
            [
              _c(
                "a-row",
                { attrs: { gutter: [16, 16] } },
                [
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formModel.documentNo
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_document_number"),
                                prop: "documentNo"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid":
                                      "trucking-cash-in-out-document-no"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.formModel.documentNo) + " "
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              disabled: _vm.disabled.branch,
                              "label-in-value": "",
                              loading: _vm.loading.branch,
                              "show-search": "",
                              "filter-option": false,
                              options: _vm.optBranch,
                              "data-testid": "trucking-cash-in-out-branch",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_branch")
                              })
                            },
                            on: { search: _vm.onSearchBranch },
                            model: {
                              value: _vm.formModel.branch,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "branch", $$v)
                              },
                              expression: "formModel.branch"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_type"), prop: "type" } },
                        [
                          _c("a-select", {
                            attrs: {
                              "allow-clear": "",
                              "show-search": false,
                              loading: _vm.loading.type,
                              disabled: _vm.disabled.type,
                              options: _vm.optTypes,
                              "data-testid": "trucking-cash-in-out-type",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_type")
                              })
                            },
                            model: {
                              value: _vm.formModel.type,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "type", $$v)
                              },
                              expression: "formModel.type"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_transaction_date"),
                            prop: "transactionDate"
                          }
                        },
                        [
                          _c("a-date-picker", {
                            staticClass: "w-100",
                            attrs: {
                              format: _vm.DEFAULT_DATE_FORMAT,
                              disabled: _vm.disabled.transactionDate,
                              disabledDate: _vm.useDisabledFromTomorrow,
                              "data-testid":
                                "trucking-cash-in-out-transaction-date"
                            },
                            model: {
                              value: _vm.formModel.transactionDate,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "transactionDate", $$v)
                              },
                              expression: "formModel.transactionDate"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_currency"),
                            prop: "currency"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              "label-in-value": "",
                              "filter-option": false,
                              loading: _vm.loading.currency,
                              disabled: _vm.disabled.currency,
                              options: _vm.optCurrencies,
                              "data-testid": "trucking-cash-in-out-currency",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_currency")
                              })
                            },
                            on: {
                              search: _vm.onSearchCurrency,
                              change: _vm.onChangeCurrency
                            },
                            model: {
                              value: _vm.formModel.currency,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "currency", $$v)
                              },
                              expression: "formModel.currency"
                            }
                          })
                        ],
                        1
                      ),
                      _vm.show.rates
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_rates"),
                                prop: "rates"
                              }
                            },
                            [
                              _c("a-input-number", {
                                attrs: {
                                  parser: _vm.reverseFormatNumber,
                                  formatter: _vm.formatterNumber,
                                  min: 1,
                                  precision: _vm.storeBaseDecimalPlace,
                                  disabled: _vm.disabled.rate,
                                  "data-testid": "trucking-cash-in-out-rates"
                                },
                                model: {
                                  value: _vm.formModel.rates,
                                  callback: function($$v) {
                                    _vm.$set(_vm.formModel, "rates", $$v)
                                  },
                                  expression: "formModel.rates"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_bank_name"),
                            prop: "companyBank"
                          }
                        },
                        [
                          _c("a-select", {
                            attrs: {
                              "show-search": "",
                              "allow-clear": "",
                              "filter-option": false,
                              "label-in-value": "",
                              "data-testid":
                                "trucking-cash-in-out-company-bank",
                              placeholder: _vm.$t("common.select-text", {
                                text: _vm.$t("lbl_bank_name")
                              }),
                              loading: _vm.loading.bank,
                              disabled: _vm.disabled.bankName,
                              options: _vm.optCompanyBanks
                            },
                            on: { search: _vm.onSearchCompanyBank },
                            model: {
                              value: _vm.formModel.companyBank,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "companyBank", $$v)
                              },
                              expression: "formModel.companyBank"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_transaction_to_from"),
                            prop: "cashInOutTransfer"
                          }
                        },
                        [
                          _c(
                            "a-radio-group",
                            {
                              attrs: { disabled: _vm.disabled.transferToFrom },
                              on: { change: _vm.onChangeTransferToFrom },
                              model: {
                                value: _vm.formModel.cashInOutTransfer,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.formModel,
                                    "cashInOutTransfer",
                                    $$v
                                  )
                                },
                                expression: "formModel.cashInOutTransfer"
                              }
                            },
                            _vm._l(_vm.optTransferSource, function(item) {
                              return _c(
                                "a-radio",
                                {
                                  key: item,
                                  attrs: {
                                    value: item,
                                    "data-testid":
                                      "trucking-cash-in-out-transfer-" +
                                      item.toLowerCase()
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("lbl_" + item.toLowerCase())
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.showContactField
                        ? [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.contactLabel.contact,
                                  prop: "supplierOrCustomer"
                                }
                              },
                              [
                                _c("a-select", {
                                  attrs: {
                                    showSearch: true,
                                    allowClear: true,
                                    disabled: _vm.disabled.contact,
                                    "label-in-value": "",
                                    loading: _vm.loading.contact,
                                    "filter-option": false,
                                    "data-testid":
                                      "trucking-cash-in-out-contact",
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.contactLabel.contact
                                    }),
                                    options: _vm.optContacts
                                  },
                                  on: {
                                    search: _vm.onSearchContact,
                                    change: _vm.onChangeContact
                                  },
                                  model: {
                                    value: _vm.formModel.supplierOrCustomer,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "supplierOrCustomer",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.supplierOrCustomer"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.contactLabel.bank,
                                  prop: "customerSupplierBank"
                                }
                              },
                              [
                                _c("a-select", {
                                  attrs: {
                                    showSearch: true,
                                    allowClear: true,
                                    "data-testid":
                                      "trucking-cash-in-out-contact-bank",
                                    disabled: _vm.disabled.contact,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.contactLabel.bank
                                    }),
                                    loading: _vm.loading.contact,
                                    options: _vm.optContactBanks,
                                    "label-in-value": ""
                                  },
                                  model: {
                                    value: _vm.formModel.customerSupplierBank,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "customerSupplierBank",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.customerSupplierBank"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : !_vm.showContactField
                        ? [
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_to_from"),
                                  prop: "transferOthers"
                                }
                              },
                              [
                                _c("a-input", {
                                  attrs: {
                                    disabled: _vm.disabled.transferSource,
                                    "allow-clear": "",
                                    "data-testid":
                                      "trucking-cash-in-out-transfer-others",
                                    placeholder: _vm.$t("common.insert-text", {
                                      text: _vm.$t("lbl_to_from")
                                    })
                                  },
                                  model: {
                                    value: _vm.formModel.transferOthers,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formModel,
                                        "transferOthers",
                                        $$v
                                      )
                                    },
                                    expression: "formModel.transferOthers"
                                  }
                                })
                              ],
                              1
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  ),
                  _c(
                    "a-col",
                    { attrs: { span: 12 } },
                    [
                      _vm.formModel.referenceNo
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_reference_number"),
                                prop: "referenceNo"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid":
                                      "trucking-cash-in-out-reference-no"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.formModel.referenceNo))]
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_cheque_number"),
                            prop: "chequeNo"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              "allow-clear": "",
                              disabled: _vm.disabled.chequeNumber,
                              "data-testid": "trucking-cash-in-out-cheque-no",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_cheque_number")
                              })
                            },
                            model: {
                              value: _vm.formModel.chequeNo,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "chequeNo", $$v)
                              },
                              expression: "formModel.chequeNo"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_bank_name_cheque"),
                            prop: "chequeBankName"
                          }
                        },
                        [
                          _c("a-input", {
                            attrs: {
                              disabled: _vm.disabled.bankNameCheque,
                              "allow-clear": "",
                              "data-testid":
                                "trucking-cash-in-out-bank-name-cheque",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_bank_name_cheque")
                              })
                            },
                            model: {
                              value: _vm.formModel.chequeBankName,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "chequeBankName", $$v)
                              },
                              expression: "formModel.chequeBankName"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_description"),
                            prop: "description"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "extra",
                              fn: function() {
                                return [
                                  _c("character-length", {
                                    attrs: { value: _vm.formModel.description }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c("a-textarea", {
                            attrs: {
                              rows: 4,
                              disabled: _vm.disabled.description,
                              "allow-clear": "",
                              "data-testid": "trucking-cash-in-out-description",
                              placeholder: _vm.$t("common.insert-text", {
                                text: _vm.$t("lbl_description")
                              })
                            },
                            model: {
                              value: _vm.formModel.description,
                              callback: function($$v) {
                                _vm.$set(_vm.formModel, "description", $$v)
                              },
                              expression: "formModel.description"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "a-form-model-item",
                        {
                          attrs: {
                            label: _vm.$t("lbl_attachment_file"),
                            help:
                              "Maximum file size is 5MB and accepted file extension is PDF, JPG, PNG, XLSX"
                          }
                        },
                        [
                          _c(
                            "a-upload",
                            {
                              attrs: {
                                name: "file",
                                multiple: false,
                                method: "post",
                                "file-list": _vm.fileList,
                                customRequest: _vm.customRequest,
                                "before-upload": _vm.beforeUpload,
                                accept: ".pdf, .png, .jpg, .xlsx"
                              },
                              on: { change: _vm.handleChange }
                            },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: {
                                    disabled: _vm.disabled.file,
                                    "data-testid":
                                      "trucking-cash-in-out-attachment",
                                    icon: "upload"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " + _vm._s(_vm.$t("lbl_upload")) + " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.formModel.status
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_status"),
                                prop: "status"
                              }
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "ant-form-text",
                                  attrs: {
                                    "data-testid": "trucking-cash-in-out-status"
                                  }
                                },
                                [_vm._v(_vm._s(_vm.formModel.status))]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm.formModel.journalId
                        ? _c(
                            "a-form-model-item",
                            {
                              attrs: {
                                label: _vm.$t("lbl_journal_number"),
                                prop: "journalId"
                              }
                            },
                            [
                              _c(
                                "RouterLink",
                                {
                                  attrs: {
                                    to: {
                                      name: "generaljournal.detail",
                                      params: { id: _vm.formModel.journalId }
                                    },
                                    target: "_blank"
                                  }
                                },
                                [
                                  _c(
                                    "a-button",
                                    {
                                      attrs: {
                                        type: "link",
                                        "data-testid":
                                          "trucking-cash-in-out-journal-number"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.formModel.journalNumber) +
                                          " "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("a-divider"),
          !_vm.disabled.row
            ? _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c(
                    "a-space",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: {
                            icon: "plus",
                            "data-testid": "trucking-cash-in-out-add-row",
                            type: "primary"
                          },
                          on: { click: _vm.addRow }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_add_row")) + " ")]
                      ),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "danger",
                            "data-testid": "trucking-cash-in-out-delete-row"
                          },
                          on: { click: _vm.confirmDelete }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_delete_row")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-table",
            {
              attrs: {
                "row-selection": {
                  selectedRowKeys: _vm.selectedRowKeys,
                  onChange: _vm.onSelectChange
                },
                columns: _vm.columns,
                "data-source": _vm.formModel.lines,
                pagination: {
                  showTotal: function(total) {
                    return _vm.$t("lbl_total_items", { total: total })
                  }
                },
                size: "small",
                "data-testid": "trucking-cash-in-out-table"
              },
              scopedSlots: _vm._u([
                {
                  key: "account",
                  fn: function(text, record) {
                    return [
                      _c("a-select", {
                        staticClass: "w-100",
                        attrs: {
                          "filter-option": false,
                          "dropdown-match-select-width": false,
                          "show-search": "",
                          options: record.isSearching
                            ? record.accountOptions
                            : _vm.optAccounts,
                          loading: record.isAccountLoading,
                          disabled: _vm.disabled.row,
                          placeholder: _vm.$t("common.select-text", {
                            text: _vm.$t("lbl_account")
                          }),
                          "label-in-value": "",
                          "data-testid": "trucking-cash-in-out-account"
                        },
                        on: {
                          search: function($event) {
                            return _vm.onSearchAccount(record, $event)
                          }
                        },
                        model: {
                          value: record.account,
                          callback: function($$v) {
                            _vm.$set(record, "account", $$v)
                          },
                          expression: "record.account"
                        }
                      })
                    ]
                  }
                },
                {
                  key: "amount",
                  fn: function(text, record) {
                    return _c("a-input-number", {
                      staticClass: "w-100",
                      attrs: {
                        parser: _vm.reverseFormatNumber,
                        formatter: _vm.formatterNumber,
                        precision: _vm.storeBaseDecimalPlace,
                        disabled: _vm.disabled.row,
                        "data-testid": "trucking-cash-in-out-amount"
                      },
                      on: { change: _vm.onChangeAmount },
                      model: {
                        value: record.amount,
                        callback: function($$v) {
                          _vm.$set(record, "amount", $$v)
                        },
                        expression: "record.amount"
                      }
                    })
                  }
                },
                {
                  key: "notes",
                  fn: function(text, record) {
                    return _c("a-textarea", {
                      staticClass: "w-100",
                      attrs: {
                        disabled: _vm.disabled.row,
                        "allow-clear": "",
                        "data-testid": "trucking-cash-in-out-notes",
                        placeholder: _vm.$t("common.insert-text", {
                          text: _vm.$t("lbl_notes")
                        })
                      },
                      model: {
                        value: record.notes,
                        callback: function($$v) {
                          _vm.$set(record, "notes", $$v)
                        },
                        expression: "record.notes"
                      }
                    })
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "footer" },
                [
                  _c(
                    "a-form-model",
                    { attrs: { layout: "inline" } },
                    [
                      _c(
                        "a-form-model-item",
                        { attrs: { label: _vm.$t("lbl_total") } },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "ant-form-text",
                              attrs: {
                                "data-testid":
                                  "trucking-cash-in-out-total-currency"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.formModel.currency
                                      ? _vm.formModel.currency.label
                                      : "-"
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ]
                      ),
                      _c("a-form-model-item", [
                        _c(
                          "span",
                          {
                            staticClass: "ant-form-text",
                            attrs: {
                              "data-testid": "trucking-cash-in-out-total-amount"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm._f("currency")(_vm.sumAmountLines))
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            2
          ),
          _c("a-divider"),
          _c(
            "div",
            { staticClass: "text-right mt-3" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { "data-testid": "trucking-cash-in-out-back" },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                  ),
                  _vm.allowPrint
                    ? [
                        _c(
                          "a-dropdown",
                          [
                            _c(
                              "a-menu",
                              {
                                attrs: { slot: "overlay" },
                                on: { click: _vm.handlePrint },
                                slot: "overlay"
                              },
                              [
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "summary",
                                    attrs: {
                                      "data-testid":
                                        "trucking-cash-in-out-print-summary"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_summary")) + " "
                                    )
                                  ]
                                ),
                                _c(
                                  "a-menu-item",
                                  {
                                    key: "detail",
                                    attrs: {
                                      "data-testid":
                                        "trucking-cash-in-out-print-detail"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.$t("lbl_detail")) + " "
                                    )
                                  ]
                                )
                              ],
                              1
                            ),
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "primary",
                                  loading: _vm.loading.print,
                                  "data-testid": "trucking-cash-in-out-print"
                                }
                              },
                              [
                                _vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " "),
                                _c("a-icon", { attrs: { type: "down" } })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.allowCancel && _vm.$can("update", "trucking-in-out")
                    ? [
                        _c(
                          "a-popconfirm",
                          {
                            attrs: {
                              title: _vm.$t("pop_confirmation"),
                              "ok-text": _vm.$t("lbl_yes"),
                              "cancel-text": _vm.$t("lbl_no")
                            },
                            on: { confirm: _vm.handleCancel }
                          },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  type: "danger",
                                  "data-testid": "trucking-cash-in-out-cancel",
                                  loading: _vm.loading.cancel
                                }
                              },
                              [_vm._v(" " + _vm._s(_vm.$t("lbl_cancel")) + " ")]
                            )
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.allowSaveDraft && _vm.$can("create", "trucking-in-out")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.saveDraft,
                            "data-testid": "trucking-cash-in-out-save-draft"
                          },
                          on: { click: _vm.handleSaveDraft }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.allowUpdate && _vm.$can("update", "trucking-in-out")
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.update,
                            "data-testid": "trucking-cash-in-out-update"
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("update")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_update")) + " ")]
                      )
                    : _vm._e(),
                  _vm.allowSubmit &&
                  (_vm.$can("update", "trucking-in-out") ||
                    _vm.$can("create", "trucking-in-out"))
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            loading: _vm.loading.submit,
                            "data-testid": "trucking-cash-in-out-submit"
                          },
                          on: {
                            click: function($event) {
                              return _vm.validateForm("submit")
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_submit")) + " ")]
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }